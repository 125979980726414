import { Component, Inject, Optional, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Field } from 'simpo-component-library/lib/sections/contact-us/contact-us.modal';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { INPUT_FIELDS_TYPE } from 'simpo-component-library';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

interface FIELD_INFO {
  icon: string,
  text: string,
  description: string,
  customIcon: boolean,
  iconTxt: string | null,
  type: INPUT_FIELDS_TYPE
}

@Component({
  selector: 'app-add-contact-fields',
  templateUrl: './add-contact-fields.component.html',
  styleUrls: ['./add-contact-fields.component.scss']
})
export class AddContactFieldsComponent {
  constructor(
    public dialogRef: MatDialogRef<AddContactFieldsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public bottomSheetRef: MatBottomSheetRef<AddContactFieldsComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomData: any,
  ){}

  public readonly availableFields: FIELD_INFO[] = [
    { icon: 'text', text: 'text', description: 'Type in a single line of text like a name or note', type: "text", customIcon: true, iconTxt: 'A|' },
    { icon: 'number', text: 'number', description: 'Type in a number without decimals or fractions', type: "number", customIcon: true, iconTxt: '#|' },
    { icon: 'money', text: 'money', description: 'Type in a monetary value, with currency', type: "money", customIcon: true, iconTxt: '$|' },
    { icon: 'calendar_today', text: 'date', description: 'Select a day of the year from the calender', type: "text", customIcon: false, iconTxt: null },
    { icon: 'check_circle_outline', text: 'checkbox', description: 'One or more yes or no choices', type: "checkbox", customIcon: false, iconTxt: null },
    { icon: 'format_list_bulleted', text: 'dropdown', description: 'Select a single choice from a menu of options you define', type: "dropdown", customIcon: false, iconTxt: null },
    { icon: 'star_border', text: 'rating', description: 'An input for a rating from 1 to 5 starts', type: "rating", customIcon: false, iconTxt: null },
  ];

  screen: "SELECT_FIELDS" | "ADD_FIELDS"| "EDIT_FIELD"  = "SELECT_FIELDS";
  public selectedInputType: INPUT_FIELDS_TYPE | null = "text";
  public newFieldLabel: string = "";
  addedField: Field[] = [];
  customAddedFields: any[] = [];
  
  fieldOptions: { label: string, value: string }[] = [ { label: '', value: '' } ];
  optionsCount: number = 1;

  ngOnInit(){
    if(this.data?.data){
      this.addedField = this.data.data;
      this.checkSelectedField();
    }
    if(this.bottomData?.data) {
      this.addedField = this.bottomData.data;
      this.checkSelectedField();
    }
    this.addCustomFields();
    this.screenSize()
  }
  screenWidth: any;
  @HostListener('window: resize',['$event'])
  screenSize(){
    this.screenWidth= window.innerWidth
  }

  get getInputType(): string {
    return this.selectedInputType as unknown as string;
  }
  get canAddMoreOption(): boolean {
    return this.fieldOptions.every((field)=> field.value?.length > 0);
  }

  fieldType:any[] = [
    {
      label: 'Name',
      value: 'Name',
      type: 'text',
      required: true,
      options: [],
      status: false
    },
    {
      label: 'Email',
      value: 'Email',
      type: 'email',
      required: true,
      options: [],
      status: false
    },
    {
      label: 'Phone',
      value: 'Phone',
      type: 'number',
      required: false,
      options: [],
      status: false
    },
    {
      label: 'Company',
      value: 'Company',
      type: 'text',
      required: false,
      options: [],
      status: false
    },
    {
      label: 'Full Address',
      value: 'Full Address',
      type: 'text',
      required: false,
      options: [],
      status: false
    },
    {
      label: 'City',
      value: 'City',
      type: 'text',
      required: false,
      options: [],
      status: false
    },
    {
      label: 'State',
      value: 'State',
      type: 'text',
      required: false,
      options: [],
      status: false
    },
    {
      label: 'Country',
      value: 'Country',
      type: 'dropdown',
      required: false,
      options: [
        {
          label : "India",
          value : "India"
        },
        {
          label : "Sri Lanka",
          value : "Sri Lanka"
        }
      ],
      status: false
    },
    {
      label: 'Postal Code',
      value: 'Postal Code',
      type: 'number',
      required: false,
      options: [],
      status: false
    },
    {
      label: 'Contact Us',
      value: 'Contact Us',
      type: 'desc',
      required: true,
      options: [],
      status: false
    }
  ]

  private addCustomFields() {
    this.customAddedFields = this.addedField.filter((field)=> Object.hasOwn(field, 'status'));
  }

  checkSelectedField(){
    let labelSet = new Set();
    for(let field of this.addedField){
      labelSet.add(field.label);
    }

    for(let type of this.fieldType) {
      if(labelSet.has(type.label)) {
        type.status = true;
      }
    }
  }

  closeModal(){
    if(this.screenWidth> 475){
      this.dialogRef.close()
    }
    if(this.screenWidth < 475){
      this.bottomSheetRef.dismiss()
    }
  }

  changeTextField(field: any, type: 'CHECK' | 'REQUIRED'){
    if (type == 'REQUIRED')
      field.status = true;
    let index = -1;
      for(let i = 0;i < this.addedField.length;i++){
        if(this.addedField[i].label === field.label){
          this.addedField[i].required = (field.required && type == 'REQUIRED');
          index = i;
          break;
        }
      }

    if(index == -1) {
      this.addedField.push({
        label: field.label,
        value: field.value,
        type: field.type,
        required: field.required,
        options: field.options,
      })
    } else if (type == 'CHECK') {
     
      if (Object.hasOwn(field, "status"))
        field.status = false
      else
        this.addedField.splice(index, 1);
    }
  }

  public addNewInput(field) {
    this.selectedInputType = field.type;
    this.screen = "EDIT_FIELD";
  }
  public cancelEdit() {
    this.screen = "ADD_FIELDS";
    this.newFieldLabel = "";
  }
  public saveEdit() {
    if (!this.selectedInputType)
      return;
    this.addedField.push({
      label: this.newFieldLabel,
      value: this.newFieldLabel,
      type: this.selectedInputType,
      required: false,
      status: true,
      options: (this.selectedInputType == "checkbox" || this.selectedInputType == "dropdown") ? this.fieldOptions.filter((option)=> option.value.length > 0) : [],
    });
    this.customAddedFields.push({
        label: this.newFieldLabel,
        value: this.newFieldLabel,
        type: this.selectedInputType,
        required: false,
        options: (this.selectedInputType == "checkbox" || this.selectedInputType == "dropdown") ? this.fieldOptions.filter((option)=> option.value.length > 0) : [],
        status: true
    });
    this.newFieldLabel = "";
    this.screen = "SELECT_FIELDS";
  }
  addMoreOption() {
    if (!this.canAddMoreOption)
      return;
    this.fieldOptions.push({ label: '', value: '' });
    this.optionsCount++;
  }
  editInput(field) {
    this.screen = "EDIT_FIELD";
    this.selectedInputType = field.type;
    this.newFieldLabel = field.value;
    this.optionsCount = field.options.length;
    this.fieldOptions = field.options;
  }
  deleteInput(field) {
    this.addedField = this.addedField.filter((f)=> f.value != field.value);
    this.customAddedFields = this.customAddedFields.filter((f)=> field.value != f.value);
  }
  removeOption(idx: number) {
    if (this.optionsCount == 1)
      return;
    this.fieldOptions = this.fieldOptions.filter((_, index: number)=> idx != index);
    this.optionsCount--;
  }
  drop(event: CdkDragDrop<unknown>) {
    moveItemInArray(this.customAddedFields, event.previousIndex, event.currentIndex);
  }
  isValueConflict(idx: number) {
    return this.fieldOptions.filter((option, index)=> option.value == this.fieldOptions[idx].value && idx != index).length > 0;
  }
}

