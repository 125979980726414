<section class="main-section">
  <!-- header-section  -->
  <div class="main-header-section" *ngIf="screenWidth > 475">
    <!-- header-left-section  -->
    <div class="header-left-section">
      <div class="header-left-main-heading">
        Blogs <span class="header-span">{{ totalBlogs }} Blogs</span>
      </div>
      <div class="header-left-sub-heading">
        Create and manage your blogs
      </div>
    </div>
    <!-- header-right-section  -->
    <div class="header-right-section" *ngIf="screenWidth > 475">
      <div class="search-bar">
        <mat-form-field class="sfull-width custom-mat-form-field" appearance="outline">
          <input matInput placeholder="Search for the blogs" [(ngModel)]="blogFilterRequest.searchText" (keyup)="handleSearch()" />
          <mat-icon matPrefix style="padding: 8px;">search</mat-icon>
        </mat-form-field>
       </div>
      <div class="blogs-pluggin" (click)="openPluginData()">
        Need Blogs Plugin
      </div>
      <div class="modal fade" id="myModal" role="dialog">durga prasad</div>

      <!-- </div> -->
      <div class="add-business-section" (click)="createBlog()">
        + Create Blog
      </div>
    </div>
    <div class="search-filter-main-section" *ngIf="screenWidth > 475">
      <!-- <div class="search-bar">
        <mat-form-field class="sfull-width custom-mat-form-field" appearance="outline">
          <input matInput placeholder="Search" [(ngModel)]="blogFilterRequest.searchText" (keyup)="handleSearch()" />
          <mat-icon matPrefix style="padding: 8px;">search</mat-icon>
        </mat-form-field>
      </div> -->
      <div class="filter-section" (click)="filterBlogs()">
        <div class="filter-icon">
          <img src="../../../../../../assets/images/master/filter_icon.svg" alt="filter_Svg" />
        </div>
        <div>Filters</div>
      </div>
    </div>

  </div>

  <!-- dropdown-section  -->
  <div class="d-flex justify-content-between" style="border-bottom: 1px solid #D0D5DD;">
    <div class="dropdown-section" *ngIf="screenWidth > 475">
      <ng-container *ngFor="let blog of Blogs">
        <div class="blog-list" (click)="onChangeBlogType(blog.value)" [ngClass]="{ 'blog-active': blog.value === blogFilterRequest.status }">
          {{blog.name}}
        </div>
      </ng-container>
    </div>
    <!-- <div class="search-bar">
      <mat-form-field class="sfull-width custom-mat-form-field" appearance="outline">
        <input matInput placeholder="Search for the blogs" [(ngModel)]="blogFilterRequest.searchText" (keyup)="handleSearch()" />
        <mat-icon matPrefix style="padding: 8px;">search</mat-icon>
      </mat-form-field>
    </div> -->
  </div>

  <!-- search and filter section  -->
  

  <ng-container *ngIf="screenWidth < 475">
    <div class="mobile-top-section">
      <div class="search-filter-resp">
        <!-- <div class="search-filter-left-resp">
          <div class="search-img-resp" (click)="search()">
            <img src="../../../../../../../../assets/images/common/search_icon.svg" alt="searchicon" class="search-img">
          </div>
          <div class="search_input" *ngIf="searchbar">
            <mat-form-field class="sfull-width" appearance="outline">
              <img src="../../../../../../../../assets/images/common/search_icon.svg" alt="searchicon"
                class="search-icon">
              <input matInput placeholder="Search" [(ngModel)]="blogFilterRequest.searchText" (keyup)="handleSearch()" />
              <img src="../../../../../../../../assets/images/close.svg" alt="close" (click)="closesearch()">
            </mat-form-field>
          </div>
          <div class="filter-section-resp">
            <img src="../../../../../../../../assets/images/master/filter_icon.svg" alt="" class="filter-img" (click)="filterBlogs()">
          </div>
        </div> -->
        <div class="dropdown-section">
          <div class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom-2"
            aria-controls="offcanvasBottom">
            {{ selectedBlogOption }}
            <span>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </span>
          </div>
          <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom-2"
            aria-labelledby="offcanvasBottomLabel">
            <div class="offcanvas-body small">
              <ng-container *ngFor="let blog of Blogs">
                <div class="blog-list" (click)="onChangeBlogType(blog.value); selectedBlogOption = blog.name"
                  [ngClass]="{ 'blog-active': blog.value === blogFilterRequest.status }" data-bs-dismiss="offcanvas">
                  {{ blog.name }}
                  <img *ngIf="blog.value === blogFilterRequest.status"
                    src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/203380c1711655372497Check%20Fill.png">
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="header-right-section">
          <div class="blogs-pluggin" (click)="openPluginData()">
            Need Blogs Plugin
          </div>
          <div class="modal fade" id="myModal" role="dialog">durga prasad</div>
          <div class="add-business-section" (click)="createBlog()">
            + Create Blog
          </div>
        </div>
      </div>

      <div class="search-filter-main-section">
        <div class="search-bar">
          <mat-form-field class="sfull-width custom-mat-form-field" appearance="outline">
            <input matInput placeholder="Search with blog title" [(ngModel)]="blogFilterRequest.searchText"
              (keyup)="handleSearch()" />
            <mat-icon matPrefix style="padding: 8px;">search</mat-icon>
          </mat-form-field>
        </div>
        <div class="filter-section" (click)="filterBlogs()">
          <div class="filter-icon">
            <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/575344c1711657551423Frame%2037718.png"
              alt="filter_Svg" />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="dropdown-section">
      <ng-container *ngFor="let blog of Blogs">
        <div class="blog-list" (click)="onChangeBlogType(blog.value)" [ngClass]="{ 'blog-active': blog.value === blogFilterRequest.status }">
          {{blog.name}}
        </div>
      </ng-container>
    </div> -->

    <!-- <div class="main-header-section"> -->
    <!-- header-left-section  -->
    <!-- <div class="header-left-section">
        <div class="header-left-main-heading">
          Blogs <span class="header-span">{{totalBlogs}} Blogs</span>
        </div>
        <div class="header-left-sub-heading">
          Create and manage your blogs
        </div>
      </div>
    </div> -->
  </ng-container>

  <div class="my-2 px-2">
    <mat-chip-set #chipGrid aria-label="Filters">
      <ng-container *ngFor="let item of filters; index as filterIndex">
        <mat-chip-row class="fw-bold" (removed)="removeFilter(item, filterIndex)">
          {{ item.fieldText }}
          <button matChipRemove [attr.aria-label]="'remove ' + item.fieldText">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </ng-container>
    </mat-chip-set>
  </div>

  <!-- Blog Listing  -->
  <div class="blog-section" [ngClass]="{'has-filter-applied': filters.length > 0}">
    <ng-container *ngIf="!emptyloader; else editLoader">
      <ng-container *ngIf="blogs.length > 0 && viewAccess; else emptyscreen">
        <ng-container *ngIf="screenWidth > 475">
          <div>
            <div class="blog-listing-main-section" *ngFor="let Blog of blogs" (click)="clickCards(Blog.id)">
              <div class="blog-left-side">
                <div class="blog-main-heading">
                  {{Blog.postTitle}}
                </div>
                <div class="author-tag-section">
                  <div class="author-side d-flex align-items-center">
                    <div class="left-side">
                      Author :
                    </div>
                    <div class="right-side ms-1" *ngIf="Blog?.author?.name === 'author'; else showName">
                      {{ (Blog?.author?.content) || 'N/A' }}
                    </div>
                    <ng-template #showName>
                      <div class="right-side ms-1">
                        {{ (Blog?.author?.name | titlecase ) || 'N/A' }}
                      </div>
                    </ng-template>
                  </div>
                  <div class="tag-side d-flex align-items-center">
                    <div class="left-side">
                      Tags :
                    </div>
                    <div class="right-side ms-1">
                      <div class="author-name">{{ Blog?.keyWords?.content || 'N/A' }}</div>
                    </div>
                  </div>
                </div>
                <div class="blog-position-section">

                  <div class="position-section-draft" *ngIf="Blog.blogStatus === 'SAVE'">
                    {{Blog.blogStatus === 'SAVE' ? 'SAVED' : ''}}
                  </div>
                  <div class="position-section" *ngIf="Blog.blogStatus === 'PUBLISH' || Blog.blogStatus === 'ALL'">
                    Published On: {{Blog.blogScheduledDate | date : 'mediumDate'}}
                  </div>
                  <div class="position-section-scheduled" *ngIf="Blog.blogStatus === 'SCHEDULED'">
                    Scheduled On: {{Blog.blogScheduledDate | date: 'mediumDate'}}
                  </div>
                  <div class="words-section">
                    {{ Blog.wordCount || 0 }} words
                  </div>
                  <div class="read-section">
                    {{Blog.readTime?Blog.readTime:'0'}} mins read
                  </div>
                  <div class="edited-section">
                    Edited &nbsp;{{Blog.updatedTimeStamp | date : 'mediumDate'}}
                  </div>
                </div>

              </div>
              <div class="blog-right-side">
                <div class="image-section" *ngIf="Blog.blogStatus !== 'PUBLISH'">
                  <img src="./../../../../../../../../assets/images/master/edit_icon.svg" alt="edit_svg"
                    (click)="editBlog(Blog)" />
                </div>
                <!-- <div class="blog-visitor-section" *ngIf="Blog.blogStatus === 'PUBLISH' ||  Blog.blogStatus === 'ALL'">
                    <div class="visitor-left-side">
                      <div class="visitor-heading">
                        Visitor
                      </div>
                      <div class="visitor-text">
                        3.2K
                      </div>
                    </div>
                    <div class="visitor-right-side">
                      <div class="visitor-heading">
                        Session Duration
                      </div>
                      <div class="visitor-text">
                        5m 1s
                      </div>
                    </div>
                  </div> -->
              </div>

            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="screenWidth < 475">
          <div>
            <div class="blog-mobile-section" *ngFor="let Blog of blogs">
              <div class="blog-mobile-top-section">
                <div class="blog-main-heading" style="cursor: pointer;" (click)="clickCards(Blog.id)">
                  {{Blog.postTitle}}
                </div>
                <div class="image-section" *ngIf="Blog.blogStatus !== 'PUBLISH'">
                  <img src="./../../../../../../../../assets/images/master/edit_icon.svg" alt="edit_svg"
                    (click)="clickCards(Blog.id)" />
                </div>
              </div>
              <div class="seperator"></div>
              <div class="author-date-section">
                <div class="author-side">
                  <div class="left-side">
                    <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/955741c1711696587105Frame.png"
                      alt="">
                  </div>
                  <div class="right-side">
                    {{ (Blog.author ? (Blog.author.content | titlecase) : 'N/A') }}
                  </div>
                </div>
                <div class="blog-position-section">
                  <div class="position-section">
                    <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/654130c1711696614182Group.png"
                      alt=""> <span>{{ (Blog.blogScheduledDate | date : 'dd MMMM yyyy') || 'N/A' }}</span>
                  </div>
                </div>
                <div>
                  <div class="position-section-save" *ngIf="Blog.blogStatus === 'SAVE'">
                    &#x2022; {{Blog.blogStatus === 'SAVE' ? 'Saved' : '' | titlecase}}
                  </div>
                  <div class="position-section-publish" *ngIf="Blog.blogStatus === 'PUBLISH'">
                    &#x2022; {{Blog.blogStatus === 'PUBLISH' ? 'Published' : '' | titlecase}}
                  </div>
                  <div class="position-section-schedule" *ngIf="Blog.blogStatus === 'SCHEDULED'">
                    &#x2022; {{Blog.blogStatus === 'SCHEDULED' ? 'Scheduled' : '' | titlecase}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <div class="w-100 position-relative">
      <p class="position-absolute bottom-0 left-0 w-100" id="hiddenElement" style="height: 100px; z-index: -1;"></p>
    </div>
  </div>
  <ng-template #editLoader>
    <section class="loader">
      <div class="loader_section">
        <!-- <div class="api_loader">
              <div class="dots-container">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
              </div>
          </div> -->
          <!-- <app-anime-loader></app-anime-loader> -->
          <div class="anime-loader" >
            <img class="loader-img"src="./../../../../../assets/animations/loader_logo.svg" alt="">
          </div>
          <ng-lottie [options]="options" (animationCreated)="animationCreated($event)" style="display:flex;margin:0 auto;width:16%" class="anime_loader">
          </ng-lottie>
      </div>
    </section>
  </ng-template>
  <ng-template #emptyscreen>
    <div *ngIf="!viewAccess" class="not-access-text">You don't have view access</div>
    <div *ngIf="viewAccess && blogs.length == 0" class="empty-part">
      No Blog Yet!!
    </div>
  </ng-template>
</section>
