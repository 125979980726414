import { Component, Input, HostListener } from '@angular/core';
import { Image } from 'simpo-component-library';
import { TemplateService } from 'src/app/services/template.service';
import { MediaSelectorComponent } from '../../popups/media-selector/media-selector.component';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
// import { ImageUploadService } from 'src/app/services/ImageUploadService/image-upload.service';
import { ComponentImageUploadService } from 'src/app/services/component-image-upload.service';
import { EventsService } from 'simpo-component-library';
import { CustomDeleteComponent } from 'src/app/master-config-components/micro-apps/crm/custom-delete/custom-delete.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent {

  constructor(
    private dialog: MatDialog,
    private templateService: TemplateService,
    private _eventHelper: EventEmmiterService,
    private imageUploadService: ComponentImageUploadService,
    private eventsService: EventsService,
  ){
    this.screenSize()
  }

  @HostListener('window:resize', ['$event'])
  screenSize(event?: any) {
    this.screenWidth = window.innerWidth;
  }

  public regenerateImageLoader: boolean = false;
  imageLoader: boolean = false;

  getUnsplaceImage() {
    this.regenerateImageLoader = true;
    this.imageLoader = true;
    setTimeout(()=>{
      this.imageLoader = false
      this.templateService.searchPhotosInUnsplash(JSON.parse(String(localStorage.getItem('bDetails')))?.subIndustryName ?? '', 0, 100).subscribe((response: any)=> {
        this.regenerateImageLoader = false;
        const index: number = Math.floor(Math.random()*(response.results.length));
        this.imageData.url = response.results[index].urls.full;
      }, (error)=> {
        this.regenerateImageLoader = false;
      })
    }, 2500);
  }
  screenWidth: any;

  @Input() imageData: Image = {
      id: '',
      url : '',
      blurhash: '',
      altText: '',

      position: {
        x: 0,
        y: 0
      },
  };
  @Input() sectionId!: string;
  // Slider related data
  max = 100;
  min = 0;

  deleteBGImage(){
    this.imageData.url = ''
  }

  async updateImage(ev:any){
    // const file = ev.srcElement.files[0];
    // console.log(file);

    // this.imageData.url = "https://i.pinimg.com/736x/d8/33/be/d833be1b6ca0a22507b95a9530ad6b3e.jpg";
    if(this.screenWidth > 475){
      let dialog = this.templateService.openDialogWithData(
        MediaSelectorComponent,
        '90vh',
        '50%',
        {multiple:false}
      )
      dialog.afterClosed().subscribe(
        (res:any)=>{
          if (res?.length) {
            this.imageData.url = res[0].assets[0].url;
            this.imageData.blurhash = res[0].assets[0].blurhash;
          }
        }
      )
      this._eventHelper.showSaveOption.emit({data:true});
    }
    if(this.screenWidth < 475){
      let dialog = this.templateService.openDialogWithData(
        MediaSelectorComponent,
        '90vh',
        'auto',
        {multiple:false}
      )
      dialog.afterClosed().subscribe(
        (res:any)=>{
          if (res?.length) {
            this.imageData.url = res[0].assets[0].url;
            this.imageData.blurhash = res[0].assets[0].blurhash;
          }
        }
      )
      this._eventHelper.showSaveOption.emit({data:true});
    }
  }

  // deleteImage(){
  //   this.imageData.url = ''
  // }
  hello()
  {
    console.log("Hello")
  }
  deleteImage(){
    const dialogRef = this.dialog.open(CustomDeleteComponent, {
      width: '400px',
      data: {
        heading: 'Delete image',
        subText: 'Are you sure you want to delete the image?',
        primaryButton: 'Delete',
        secondaryButton: 'Cancel'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.response === 'success') {
        this.imageData.url = ''
        this.templateService.openSnack('Favicon deleted successfully', 'Ok');
      }
    });
  }

  horizontalPosition(){
    this.eventsService.objectPositionChangeCheck.emit({id: this.sectionId+this.imageData.id,position:this.imageData.position})
  }
}
