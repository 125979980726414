import { DialogRef } from '@angular/cdk/dialog';
import { Component, HostListener, Inject, TemplateRef, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FontFamily } from 'src/app/master-config-components/micro-apps/website/constants/FontFamilty.constant';
import { BUTTON_SHAPE } from 'src/app/master-config-components/micro-apps/website/enums/website.enum';
import { V3WidgetsComponent } from '../../popups/v3-widgets/v3-widgets.component';
import { TemplateService } from 'src/app/services/template.service';
import { V3AddSectionComponent } from 'src/app/master-config-components/micro-apps/invoice/popups/v3-add-section/v3-add-section.component';
import { V3ViewSiteComponent } from '../../popups/v3-view-site/v3-view-site.component';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { EditorEventService } from 'src/app/services/editor-event.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PageListComponent } from '../../page-list/page-list.component';
import { PageData, Section } from '../../editor.modal';
import { WarningPopupComponent } from '../../popups/warning-popup/warning-popup.component';
import { v3FontFamilies, v3FontFamiliesAfterSignup } from 'src/app/constants/common-constant';
import { WebisiteService } from 'src/app/master-config-components/micro-apps/website/webisite.service';
import { DomSanitizer } from '@angular/platform-browser';
import { subscribe } from 'diagnostics_channel';
import { CreateNewPageComponent } from '../../create-new-page/create-new-page.component';
import { WebsiteEditor } from 'src/app/website-generation/website-editor.service';

@Component({
  selector: 'app-v3-global-styles',
  templateUrl: './v3-global-styles.component.html',
  styleUrls: ['./v3-global-styles.component.scss', './mq-v3-global-styles.component.scss']
})
export class V3GlobalStylesComponent {
  readonly FontFamilyList = FontFamily;
  selectedOption: any;
  status:any
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<V3GlobalStylesComponent>,
    private templateService: TemplateService,
    private eventService: EditorEventService,
    private snackBar: MatSnackBar,
    private router: Router,
    private websiteService:WebisiteService,
    public ds: DomSanitizer,
    private route:ActivatedRoute,
    private editorService: WebsiteEditor
  ) {
    this.subscribeQureParams();
    this.getScreenSize();
   }
  scrWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrWidth = window.innerWidth;
  }
  fontFamilies = v3FontFamiliesAfterSignup;

  businessDetails: any;
  websiteStatus: any;
  private templateId: string | null = null;
  public selectedFont: string | null = null;
  selectedPage: string | null = null;
  currentPageId: string = '';
  getWebsiteLoader:boolean = true;
  mobileStatus: any;
  // websiteStatus: "NOT_PUBLISHED" | "PUBLISHED" = "NOT_PUBLISHED";
  ngOnInit() {
    // this.websiteStatus = window.localStorage.getItem('status')

    // console.log(this.websiteStatus)
    let bDetails = window.localStorage.getItem('bDetails');
    this.mobileStatus = window.localStorage.getItem('status')
    console.log('mobile view status is', this.mobileStatus)
    console.log('template pages are', this.templatePage)


    if (bDetails) {
      this.businessDetails = JSON.parse(bDetails);
      this.templateId = this.businessDetails.templateId;
    }
    this.getWebsite();
    this.getThemePallet()
  }

  subscribeQureParams(){
    //
    this.route.queryParams.subscribe({
      next:(value:any)=>{
        this.status = value.status;
        // console.log(this.status)
      }
    })
  }
  templatePage: any[] = [];
  getWebsite() {
    if (!this.templateId)
      return;


    this.templateService.getWebsiteTemplate(this.templateId).subscribe((response: any) => {
      this.templatePage = response.data;
      this.unActivateLink(this.templatePage[0].id)
      this.selectedPage = this.templatePage[0].pageName
      this.currentPageId = this.templatePage[0].id
      // this.fontFamilies.forEach((font)=> {
      //   const key: string[] = [];
      //   this.templatePage[0].fonts.forEach((f)=> {
      //     // key.push(Object.keys(f));
      //
      //   })
      //   if (font.headingFontValues.fontFamily == key[0] || font.headingFontValues.fontFamily == key[1]) {
      //     this.selectedFont = font.displayHeading;
      //   }
      // })
      this.applyFontFamily()
    })
  }

  applyFontFamily(){

    if(this.templatePage[0]?.fonts && this.templatePage[0]?.fonts[0]){
      Object.keys(this.templatePage[0].fonts[0]).forEach((key, index) => {
        const value = this.templatePage[0].fonts[0][key];
        document.documentElement.style.setProperty('--bs-head-font-family', key);
        document.documentElement.style.setProperty('--bs-head-font-weight', value);
      });
    }
    if(this.templatePage[0]?.fonts && this.templatePage[0]?.fonts[1]){
      Object.keys(this.templatePage[0].fonts[1]).forEach((key, index) => {
        const value = this.templatePage[0].fonts[0][key];
        document.documentElement.style.setProperty('--bs-body-font-family', key);
        document.documentElement.style.setProperty('--bs-body-font-weight', value);
      })
    }
  }

  themes: any[] = [];
  getThemePallet(){
    this.templateService.getAllThemePallet().subscribe((res:any) => {
      this.themes = res.data;
    })
  }

  goBack() {
    this.router.navigate(['/admin/website/preview'])
  }
  selectedGlobal: string = ''
  open(ev: string): void {
    console.log(ev)
    this.selectedGlobal = ev;
  }
  openWidgets() {
    this.templateService.openDialog(
      V3WidgetsComponent,
      '60%',
      '50%',
      {}
    )
  }
  viewPopup() {
    if(this.businessDetails.subscriptionUpgrade === false)
    {
      this.viewPerview();
    }
    else
    {
      if(this.scrWidth > 475){
        this.templateService.openDialog(
          V3ViewSiteComponent,
          'auto',
          '35%',
          {}
        )
      }
      if(this.scrWidth < 475){
        this.templateService.openBottomSheet(
          V3ViewSiteComponent,
          "",
          '',
        )
      }
    }
  }

  changePage(event, pageName: string) {
    this.currentPageId = event;
    this.selectedPage = pageName
    this.unActivateLink(event);
    // this.eventService.changePageEvent.emit({ id: event })
  }

  savePage() {
    console.log('template pages are', this.templatePage)
    let filteredPages = this.templatePage.filter(page => page.components.some(component => component.sectionType === "addNewSection"))
    console.log('add new section', filteredPages)

    if (filteredPages.length === 0){
      this.getWebsiteLoader = true;
      this.templateService.saveTemplatePage(this.templatePage).subscribe((res: any) => {
        this.snackBar.open('Page Updated successfully', 'close', {duration: 1500});
        this.getWebsiteLoader = false;

      }, () => {
        this.getWebsiteLoader = false;
      })
    }
    else{
      this.snackBar.open('Page can not be saved since no valid sections. Please add sections all pages to save','close',{
        duration: 4000
      })
    }
  }

  createNewPage() {
    this.getWebsiteLoader = true;
    let pageData: PageData = new PageData();
    pageData.businessName = this.businessDetails.name;
    pageData.path = this.templatePage.length < 2 ? '/New_Page' : '/New_Page_' + String(this.templatePage.length - 1);
    pageData.pageName = this.templatePage.length < 2 ? 'New Page' : ('New Page ' + String(this.templatePage.length - 1));
    pageData.updatedTimeStamp = new Date()
    pageData.homePageId = this.templatePage[0].id
    pageData.id = '';
    pageData.websiteThemeId = this.templatePage[0].websiteThemeId ? this.templatePage[0].websiteThemeId : this.themes[0].id;

    pageData.components.push(this.createAddSection())
    this.templatePage.push(pageData)

    // ADDING DATA IN HEADER
    // this.addingLinksToHeader(pageData.pageName);

    this.templateService.saveTemplatePage(this.templatePage).subscribe((res: any) => {
      //this.templatePage[this.templatePage.length - 1].id = res.data[res.data.length - 1].id;
      this.templatePage = res.data;
      this.snackBar.open('page created successfully', 'close', {duration: 1500})
      this.changePage(this.templatePage[this.templatePage.length - 1].id, pageData.pageName)
    }, (err) => {
      this.getWebsiteLoader = false;
    })
  }

  addingLinksToHeader(pageName: string){
    if(!this.templatePage[0].components[0].content.navbarButtons || this.templatePage[0].components[0].content.navbarButtons.length == 0){
      let homePageLink = {
        label: 'Home' ,
        redirectionUrl: "",
        pageId : this.templatePage[0].id,
        status: false
      }
      this.templatePage[0].components[0].content.navbarButtons.push(homePageLink);
    }
    this.unActivateLink(null);

    let link = {
      label: pageName ,
      redirectionUrl: "/new_page",
      pageId : "",
      status: true
    }

    this.templatePage[0].components[0].content.navbarButtons.push(link);
  }

  unActivateLink(pageId: any){
    for(let link of this.templatePage[0].components[0].content.navbarButtons){
      if(!link.folder) {
        if(link.pageId === pageId){
          link.status = true;
        } else {
          link.status = false;
        }
      } else {
        let isFound = false;
        for(let page of link.folder.pages) {
          if(page.pageId === pageId) {
            isFound = true;
          }
        }
        if(isFound)
          link.status = true;
        else
          link.status = false
      }
    }
  }

  addingPageInHeader(pageName) {
    let header = this.templatePage[0].components[0];
    let navButton = {
      "label": pageName,
      "redirectionUrl": "/newPage",
      "pageId": "",
      "status": true
    }
    header.content.navbarButtons.push(navButton);
  }

  createAddSection() {
    let addSection = new Section();
    addSection.sectionType = 'addNewSection';
    return addSection;
  }


  pageListComponent() {
    if(this.scrWidth > 475){
      let dialog = this.templateService.openDialog(
        PageListComponent,
        '',
        '',
        { data: this.templatePage, loader: this.getWebsiteLoader }
      )

      dialog.afterClosed().subscribe((res: any) => {
        if (!res?.action)
          return;

        if (res.action === 'changePage') {
          this.changePage(res.id, res.pageName)
        }
        else if (res.action === 'addPage') {
          if(res.duplicate){
            this.createDuplicatePage(res.page);
          } else {
            this.showPageList()
          }
        }
        else if (res.action === 'deletePage') {
          this.deletePage(res.index, res.folderId);
        }
        else if (res.action === 'addFolder') {
          this.createFolder(res.folder);
        }
      })
    }
    if(this.scrWidth < 475){
      let _bottomSheet = this.templateService.openBottomSheet(
        PageListComponent,
        { data: this.templatePage, loader: this.getWebsiteLoader },
        'bottom-sheet-height'
      )

      _bottomSheet.afterDismissed().subscribe((res: any) => {
        if (!res?.action)
          return;

        if (res.action === 'changePage') {
          this.changePage(res.id, res.pageName)
        }
        else if (res.action === 'addPage') {

          if(res.duplicate){
            this.createDuplicatePage(res.page);
          } else {
            this.showPageList()
          }
        }
        else if (res.action === 'deletePage') {
          this.deletePage(res.index, res.folderId);
        }
        else if (res.action === 'addFolder') {
          this.createFolder(res.folder);
        }
      })
    }
  }

  createFolder(data: any){
    this.templateService.createFolder(data).subscribe((res: any) => {
      this.createFolderInHeader(data, res.data.folderId);
      this.savePage();
    })
  }

  createFolderInHeader(data: any, folderId: any){
    let folder = {
      label: data.folderName,
      status: false,
      showHeader : true,
      folder : {
        folderId: folderId,
        pages: []
      }
    }
    this.templatePage[0].components[0].content.navbarButtons.push(folder);
  }

  createDuplicatePage(page: string){
    this.getWebsiteLoader = true;
    let duplicatePage = JSON.parse(JSON.stringify(page));
    duplicatePage.path = this.templatePage.length < 2 ? '/New_Page' : '/New_Page_' + String(this.templatePage.length - 1);
    duplicatePage.pageName = this.templatePage.length < 2 ? 'New Page' : ('New Page ' + String(this.templatePage.length - 1));
    duplicatePage.updatedTimeStamp = new Date()
    duplicatePage.homePageId = this.templatePage[0].id
    duplicatePage.id = '';

    this.removeHeaderAndFooterIfPresent(duplicatePage);
    this.templatePage.push(duplicatePage)

    this.templateService.saveTemplatePage(this.templatePage).subscribe((res: any) => {
      this.templatePage = res.data
      this.changePage(this.templatePage[this.templatePage.length - 1].id, duplicatePage.pageName)
    }, (err) => {
      this.getWebsiteLoader = false;
    })

  }

  removeHeaderAndFooterIfPresent(page: any){
    if(page.pageName != 'Home'){
      if(page.components[0].sectionType == 'header'){
        page.components.splice(0,1);
      }
      if(page.components[page.components.length - 1].sectionType === 'footer'){
        page.components.splice(page.components.length - 1, 1);
      }
    }
  }

  deletePage(index: number, folderId: string) {
    this.warningPopup().subscribe((res: any) => {
      if (res.delete) {
        this.getWebsiteLoader = true;
        let deletedPageName = this.templatePage[index].pageName;
        let pageID = this.templatePage[index].id;

        this.templatePage.splice(index, 1);
        this.templateService.deleteWebsitePage(pageID).subscribe((res: any) => {
          if(folderId)
            this.removePageFromFolder(folderId, pageID)
          else
            this.removingNavigationFromHeader(pageID)

          if (deletedPageName === this.selectedPage) {
            this.changePage(this.templatePage[0].id, this.templatePage[0].pageName);
          }else{
            this.getWebsiteLoader = false
          }
          this.snackBar.open('Page Deleted Successfully', 'close', { duration: 3000 })
        },() => {
          this.getWebsiteLoader = false;
        })

      }
    })
  }

  removePageFromFolder(folderId: any, pageId: any){
    let removedPage;

    for(let button of this.templatePage[0].components[0].content.navbarButtons) {
      if(button?.folder && button.folder.folderId === folderId) {
        let deleteIndex = -1;
        for(let i = 0;i < button.folder.pages.length;i++) {
          let page = button.folder.pages[i];
          if(page.pageId === pageId) {
            deleteIndex = i;
            removedPage = page;
          }
        }
        if(deleteIndex > -1) {
          button.folder.pages.splice(deleteIndex, 1);
          break;
        }
      }
    }
  }

  removingNavigationFromHeader(pageID: any){

    let index = -1;
    for(let i = 0;i < this.templatePage[0].components[0].content.navbarButtons.length;i++) {
      let button = this.templatePage[0].components[0].content.navbarButtons[i];
      if(button.pageId === pageID) {
        index = i;
        break;
      }
    }
    if(index != -1) {
      this.templatePage[0].components[0].content.navbarButtons.splice(index,1);
    }

    if(this.templatePage[0].components[0].content.navbarButtons.length === 1 &&
      this.templatePage[0].components[0].content.navbarButtons[0].pageId === this.templatePage[0].id) {
        this.templatePage[0].components[0].content.navbarButtons.splice(0,1);
      }
  }

  warningPopup() {
    let width= window.innerWidth > 475 ? '400px' : '94%'
    let height= window.innerWidth > 475 ? '268px' : '200px'
    let warningPopupModel = this.templateService.openDialog(
      WarningPopupComponent,
      height,
      width,
      { data: null }
    );

    return warningPopupModel.afterClosed()
  }

  closeEditor(){
    // console.log('data')/
    this.eventService.showEditor.emit({data:false})
  }

  viewPerview(){
    window.open(this.businessDetails.domainUrl ? this.businessDetails.domainUrl : this.businessDetails.websiteUrl, '_blank')?.focus();
  }

  showPageList() {
    let width= window.innerWidth > 475 ? '70%' : '100%'
    let height= window.innerWidth > 475 ? '90%' : '100%'
    let dialog = this.templateService.openDialog(CreateNewPageComponent, height, width, {subId: this.businessDetails.subIndustryId});
    dialog.afterClosed().subscribe((res: any) => {
      if (res?.page) {
        if (res.page.name === 'Blank Page') {
          this.createNewPage();
        } else {
          this.createExistingPage(res.page.id);
        }
      }
    })
  }

  createExistingPage(pageId: any) {
    this.getWebsiteLoader = true;

    this.templateService.getPageBySubIndustryId(this.businessDetails.subIndustryId, pageId).subscribe((pageRes: any) => {
      pageRes.data.id = '';
      pageRes.data.path = this.templatePage.length < 2 ? '/New_Page' : '/New_Page_' + String(this.templatePage.length - 1);
      this.templatePage.push(pageRes.data);
      this.applyThemeAndFont(this.templatePage[this.templatePage.length - 1]);

      this.templateService.saveTemplatePage(this.templatePage).subscribe((res: any) => {
        this.templatePage = res.data;
        this.snackBar.open('page created successfully', 'close', {duration: 1500})
        this.changePage(this.templatePage[this.templatePage.length - 1].id, pageRes.data.pageName)
      }, (err) => {
        this.getWebsiteLoader = false;
      })
    }, () => {
      this.snackBar.open('No Page Found', 'close', {duration: 2000});
      this.getWebsiteLoader = false;
    })
  }

  applyThemeAndFont(page: any){
    let selectedTheme;
    for(let theme of this.themes) {
      if(theme.id === this.templatePage[0].websiteThemeId) {
        selectedTheme = theme;
      }
    }

    if(selectedTheme)
      this.editorService.applyThemeForSinglePage(selectedTheme  , page);
    page.fonts = JSON.parse(JSON.stringify(this.templatePage[0].fonts))
  }

  // Font Families
  selectedFontFamily(item){
    this.selectedFont = item.displayHeading;
    if (item.headingFontValues && typeof item.headingFontValues.fontFamily === 'string') {
      // Set the custom property on the document root
      document.documentElement.style.setProperty('--bs-head-font-family', item.headingFontValues.fontFamily);
    }
    if(item.headingFontValues && typeof item.headingFontValues.fontWeight === 'string'){
      document.documentElement.style.setProperty('--bs-head-font-weight', item.headingFontValues.fontWeight);
    }
    if(item.bodyFontValues && typeof item.bodyFontValues.fontFamily === 'string'){
      document.documentElement.style.setProperty('--bs-body-font-family',item.bodyFontValues.fontFamily)
    }
    if(item.bodyFontValues && typeof item.bodyFontValues.fontWeight === 'string'){
      document.documentElement.style.setProperty('--bs-body-font-weight',item.bodyFontValues.fontWeight);
    }

    for(let page of this.templatePage) {
      page.fonts = []
      let heading = {};
      heading[item.headingFontValues.fontFamily] = item.headingFontValues.fontWeight;

      let desc = {};
      desc[item.bodyFontValues.fontFamily] = item.bodyFontValues.fontWeight;

      page.fonts.push(heading)
      page.fonts.push(desc)
    }
  }
  loader = false;
  changeStatus(){
    this.loader = true;
    let data = {
      bId:this.businessDetails.id,
      status : 'PUBLISHED'
    }
    this.websiteService.updateWebsiteStatus(data).subscribe((res:any) => {
      this.websiteStatus = 'PUBLISHED';
      this.loader = false;
      window.localStorage.setItem('status','PUBLISHED');
    },() => {
      this.loader = false;
    })
  }
}
