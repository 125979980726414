import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessServiceService {

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
  ) { }

  openDialog(componentName:any, height:any, width:any, dataModel:any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      // position:{right:'0px',top:'0px'}
    });
  }

  openBottomSheet(componentName: any,data:any){
    return this. _bottomSheet.open(componentName, {
      data:data,

    });
  }

  openRightPopup(componentName:any, height:any, width:any, maxWidth:any, dataModel:any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      maxWidth:maxWidth,
      data: dataModel,
      disableClose: true,
      position:{right:'0px',top:'0px'},
    });
  }


  getAllBusiness(data){
    return this.http.put(
      environment.baseUrl+`business/business/list`,data
    )
  }

  getAllBusinessByStaffId(staffId){
    return this.http.get(
      environment.baseUrl+`staff/staff/${staffId}`
    )
  }
  markAsFav(data){
    return this.http.put(
      environment.baseUrl+`staff/staff/business/favourite?staffId=${data.staffId}&businessId=${data.businessId}&isFavourite=${data.isFav}`,data
    )
  }

  markAsDefault(staffId:any){
    return this.http.get(
      environment.baseUrl+`staff/staff/business/by/staff?staffId=${staffId}`
    )
  }

  getDetailsByBusinessId(businessId: any, staffId?: any) {
    if(!staffId) {
      return this.http.get(
        environment.baseBusinessUrl + `business/${businessId}`
      );
    } else {
      return this.http.get(
        environment.baseBusinessUrl + `business/${businessId}?staffId=${staffId}`
      );
    }
  }

  getAllCountries(){
    return this.http.get(
      environment.serverIp+`master/location/country?size=1000&page=0`
    )
  }

  createAccount(data: any) {
    return this.http.post(environment.baseUrl + `staff/staff/business`, data);
  }

  getCurrencySymbol(businessId:any){
    return this.http.get(
      environment.baseUrl+`business/business/preference?businessId=${businessId}`
    )
  }
  updateBusinessDetails(data){
    return this.http.put(
      environment.baseUrl+`business/business`,data
    )
  }
  deleteBusiness(payload:any){
    return this.http.put(
      environment.baseUrl+`business/business/delete/new`, payload
    )
  }

  updatePaymentGatewayStatus(enabled: boolean, businessId: string) {
    return this.http.patch(
      environment.baseUrl + `business/business/update/pg?pgEnabled=${enabled}&businessId=${businessId}`, ''
    )
  }

  updateSignUpConfig(enabled: boolean, businessId: string) {
    return this.http.put(
      environment.baseUrl + `business/v3/business/${businessId}/signup/toggle?status=${enabled}`, ''
    )
  }
}
