<section class="main_section" *ngIf="!pageLoader && viewAccess">
  <div class="page_title_description">
    <div class="page_title">Site Settings</div>
    <div class="description">
      Manage your website's status and custom domain.
    </div>
  </div>
  <div class="web_status">
    <div class="heading_status">
      <div class="section_heading">Website Status</div>
      <div class="status" *ngIf="stat != 'NOT_PUBLISHED'" [ngClass]="
                websiteStatus === 'PRIVATE' ? 'status_red' : ''
                ||
                websiteStatus === 'PUBLIC' ? 'status' : ''
                ||
                websiteStatus === 'DISABLED' ? 'status_disabled' : ''
              ">
        &#x2022; {{websiteStatus | titlecase}}
      </div>
      <div class="status" *ngIf="stat === 'NOT_PUBLISHED'" [ngClass]=" stat === 'NOT_PUBLISHED' ? 'status_red' : ''">
        &#x2022; Not Published
      </div>

    </div>
    <div class="heading_status_1">
      <div class="website-status">
        <div class="website-status-text" *ngIf="stat == 'NOT_PUBLISHED'">
          Website is currently Not Published
          <div class="website-status-message" *ngIf="stat == 'NOT_PUBLISHED'">
              It is not Published
          </div>
        </div>
        <div class="website-status-text" *ngIf="stat != 'NOT_PUBLISHED'">
          Website is currently
          {{websiteStatus === 'PUBLISHED' ? 'Published' : '' || websiteStatus === 'PRIVATE' ? 'Private' : '' ||
          websiteStatus === 'DISABLED' ? 'Disabled' : ''  }}
          <div class="website-status-message" *ngIf="stat != 'NOT_PUBLISHED'">
            {{websiteStatus === 'PUBLISHED' ? 'Anyone can see the website' : ''
            || websiteStatus === 'PRIVATE' ? 'Only you can see the website' : ''
            || websiteStatus === 'DISABLED' ? 'The website and its features are disabled' : ''
          }}
          </div>
        </div>
        <div class="btn" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" *ngIf="stat != 'NOT_PUBLISHED'">Change</div>
      </div>
      <div [ngClass]="{'status_array' : editAccess}">
        <div class="collapse multi-collapse" id="multiCollapseExample2">
          <div class="hr_line"></div>
          <mat-radio-group *ngIf="editAccess" aria-label="Select an option" class="status_array"
            [(ngModel)]="websiteStatus" (change)="changeWebsiteStatus($event)">
            <ng-container *ngFor="let item of webStatus">
              <div class="single_status">
                <mat-radio-button [value]="item.value" class="radio_btn" [checked]="item.status" [color]="color">
                  <div class="button_name">{{item?.name | titlecase}}</div>
                </mat-radio-button>
                <div class="message">{{item.message}}</div>
              </div>
            </ng-container>
          </mat-radio-group>
          <div class="not-access-text mt-15" *ngIf="!editAccess">You don't have edit access</div>
        </div>
      </div>
    </div>
  </div>


  <!-- <div class="web_status">
        <div class="heading_status">
            <div class="section_heading">Search Indexing</div>
            <div class="status" [ngClass]="
                searchIndex === 'ENABLE' ? 'status' : ''
                ||
                searchIndex === 'DISABLE' ? 'status_disabled' : ''
            ">
                &#x2022; {{searchIndex | titlecase}}
            </div>
        </div>
        <div class="hr_line"></div>
        <div class="sub_division">
            <div class="sub_heading">Website is currently not being indexed</div>
            <div class="sub_desc">Google, Bing and other search tools are not indexing your website</div>
        </div>
        <div class="status_array">
            <mat-radio-group aria-label="Select an option" class="status_array" [(ngModel)]="searchIndex">
                <ng-container *ngFor="let item of searchIndexing">
                    <div class="single_index">
                        <mat-radio-button [value]="item.value" class="radio_btn" [checked]="item.status" [color]="color">
                            <div class="button_name">{{item?.name | titlecase}}</div>
                        </mat-radio-button>
                        <div class="message">{{item.message}}</div>
                    </div>
                </ng-container>
            </mat-radio-group>
        </div>
    </div> -->
  <div class="web_status">
    <div *ngIf="dnsVerified != 'Verified'">
      <div class="heading_status">
        <div class="section_heading">
          Site Domain
        </div>
      </div>
      <div class="site-domain-top">
        <div class="sub_division">
          <div class="sub_heading">Current Site Link</div>
          <div class="https_url">
            https://{{(domainUrl).toLowerCase()}}.{{urlEndPoit}}
          </div>
        </div>
        <div class="btn" data-bs-toggle="collapse" href="#multiCollapseExample1" aria-expanded="false"
          aria-controls="multiCollapseExample1">Change</div>
      </div>
      <div class="collapse multi-collapse" id="multiCollapseExample1">
        <div class="hr_line"></div>
        <div class="url-body" *ngIf="editAccess">
          <div class="url_domain">
            <div class="sub_desc">Must be alphanumeric (A-Z, 0-9) with dashes between words.</div>
            <mat-form-field appearance="outline" class="url_input">
              <input matInput [(ngModel)]="domainUrl" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"><button
                class="end_domain">.simpo.ai</button>
            </mat-form-field>
            <div class="https_url">
              https://{{(domainUrl).toLowerCase()}}.{{urlEndPoit}}
            </div>
          </div>
          <div class="update_btn">
            <button (click)="updateWebsiteUrl()">Update</button>
          </div>
        </div>
        <div class="not-access-text mt-15" *ngIf="!editAccess">You don't have edit access</div>
      </div>
    </div>
    <!-- <div class="custome_domain_urls" id="custom_domain">
            <div class="cd_heading">Get a custom domain name!</div>
            <div class="cd_hint">
                <div class="domain_icon">
                    <img src="./../../../../../assets/images/common/domain.svg" alt="domain">
                </div>
                <div class="domain_hint">
                    Build your professional brand with a unique web address for free when you upgrade your plan!
                </div>
            </div>
            <div class="custom_domains"> -->
    <!-- <ng-container>
                    <div class="single_domain">
                        <div class="check_img">
                            <img src="./../../../../../assets/images/common/check.svg" alt="check_mark">
                        </div>
                        <div class="domain_url">
                            smartsolutions.com
                        </div>
                        <div class="status_btn">
                            <div class="public_status">&#x2022; Public</div>
                            <div class="get_btn">
                                <button>Get</button>
                            </div>
                        </div>
                    </div>
                </ng-container> -->
    <!-- <div class="search_custom_domain">
                    <mat-form-field appearance="outline" class="search_domain">
                        <mat-icon class="search_Icon" matPrefix>search</mat-icon>
                        <input matInput placeholder="Seach for domain name here" [(ngModel)]="domainName"/>
                        <button class="search_btn" (click)="searchDomain()">Search</button>
                    </mat-form-field>
                    <button class="search_Domain_button" onclick="entri.purchaseDomain(config)">Search Domain</button>
                </div> -->
    <!-- <div class="example">
                    <input type="text" placeholder="Seach for domain name here" name="search" [(ngModel)]="domainName">
                    <button type="submit" (click)="searchDomain()">Search</button>
                </div> -->
    <!-- <div class="search_domain">
                    <button (click)="searchDomain()">Search Domain</button>
                </div> -->
    <!-- </div>
        </div> -->
  </div>
  <div class="web_status">
    <div class="heading_status">
      <div class="section_heading">Domain</div>
    </div>
    <div class="heading_status_1">
      <div class="heading">
        <div class="sub-heading-1">
           Get your new domain
        </div>
        <!-- <button type="button" class="search-button" onclick="entri.purchaseDomain(config)" *ngIf="editAccess"> Get a
          domain for your business</button> -->
      </div>
      <div class="heading_button">
        <div class="sub-heading-2">
         <p class="fst-italic mb-0"> Secure your ideal domain with Simpo! Thanks to our partnership with IONOS,</p><p class="fw-bolder">Get upto 12$ discount on buying your domain , This offer is only for Simpo users exclusively*</p>
        </div>
        <div>
        <button type="button" class="linking_domain" onclick="entri.purchaseDomain(config)" *ngIf="editAccess"> <mat-icon class="srch-icon">search</mat-icon>Search
         </button>
        </div>
      </div>
      <div class="container mt-3">
        <div class="row">
          <!-- <div class="col-md-6"> -->
          <div class="search-bar1">
            <!-- <span class="search-icon"><img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/155570c1710576306148Search.png" alt=""></span> -->
            <!-- <input type="text" class="search-input" placeholder="Looking for a domain?"> -->
            <!-- <span class="search-domain">Search for your domain</span> -->
            <!-- <mat-form-field class="sfull-width" appearance="outline">
              <input matInput placeholder="Search" />-->
            <!--<mat-icon matPrefix style="padding: 8px;">search</mat-icon>
              <-- <button type="button" class="search-button" onclick="entri.purchaseDomain(config)">Search</button> -->
            <!--</mat-form-field> -->
            <!-- <button type="button" class="search-button" onclick="entri.purchaseDomain(config)" *ngIf="editAccess">Claim Your Domain Today</button> -->
            <button type="button" class="search-button" *ngIf="!editAccess" (click)="showSnackBar()">Search</button>
          </div>
          <!-- </div> -->
        </div>
      </div>
      <!-- <div class="domain-section">
          <div class="domain-left-section">
            <div class="domain-section-icon"><img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/544688c1710578485041Dark.png" alt=""></div>
            <div class="domain-section-link">splendidstonese.com</div>
          </div>
          <div class="domain-right-section">
            <button>Get it</button>
          </div>
        </div>
        <div class="domain-section">
          <div class="domain-left-section">
            <div class="domain-section-icon"><img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/544688c1710578485041Dark.png" alt=""></div>
            <div class="domain-section-link">splendidstonese.com</div>
          </div>
          <div class="domain-right-section">
            <button>Get it</button>
          </div>
        </div>
        <div class="domain-section">
          <div class="domain-left-section">
            <div class="domain-section-icon"><img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/544688c1710578485041Dark.png" alt=""></div>
            <div class="domain-section-link">splendidstonese.com</div>
          </div>
          <div class="domain-right-section">
            <button>Get it</button>
          </div>
        </div> -->
    </div>
    <div class="heading_status_1">
      <div class="use_domain">
        <div class="cd_heading">Existing domain</div>
        <div class="cd_description">
          <div class="sub-heading-2">Already have a domain with Google, GoDaddy, or somewhere else? Easily connect or
            transfer it to your site. Start by verifying your current domain</div>
          <div class="link_domain">
            <!-- <mat-form-field appearance="outline" class="url_input">
                  <button class="http">
                      https://
                  </button>
                  <input matInput placeholder="example.com" [(ngModel)]="linkingDomain"> -->
            <div *ngIf="dnsDomainUrl" class="link_domain_input">
              <div style="margin-right: 10px;">
                <mat-form-field class="url_input" appearance="outline">
                  <input placeholder="domain.com" matInput [(ngModel)]="dnsDomainUrl"><button class="domain_verify"
                    (click)="linkDomain()">{{dnsDomainBtnText}}</button>
                </mat-form-field>
              </div>

              <div>{{dnsVerified}}</div>
            </div>
            <div *ngIf="!dnsDomainUrl">
              <button class="linking_domain" (click)="linkDomain()">
                <span class="spinner" *ngIf="loader">
                  <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
                </span>
                <span *ngIf="!loader">Link Domain</span>
              </button>
            </div>

            <!-- </mat-form-field> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="web_status" *ngIf="websiteType === 'E_COMMERCE'">
    <div *ngIf="dnsVerified != 'Verified'">
      <div class="heading_status">
        <div class="section_heading">
          Configuration
        </div>
      </div>
      <div class="website-config">
        <div class="sub_division">
          <div class="sub_heading">Payment Enabled</div>
          <div class="form-check form-switch">
            <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="businessDetails.paymentGatewayEnabled" (ngModelChange)="updatePatmentStatus()"
              role="switch">
          </div>
        </div>
        <div class="sub_division">
          <div class="sub_heading">Signup Enabled</div>
          <div class="form-check form-switch">
            <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="businessDetails.signupEnabled" (ngModelChange)="updateSignupConfig()"
              role="switch">
          </div>
        </div>
        <div class="sub_division"  *ngIf="websiteType === 'E_COMMERCE'">
          <div class="sub_heading">Login Required</div>
          <div class="form-check form-switch">
            <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="businessDetails.loginRequired" (ngModelChange)="updateLoginRequired()"
              role="switch">
          </div>
        </div>
        <div class="order-quantity-section">
          <div class="sub_division" style="margin-top: 15px;">
            <div class="sub_heading">Minimum order amount</div>
            <div class="input-container">
              <input class="input" type="number" [(ngModel)]="this.businessDetails.minOrderAmount">
            </div>
          </div>
          <div class="sub_division" style="margin-top: 15px;">
            <div class="sub_heading">Delivery Charges</div>
            <div class="input-container">
              <input class="input" type="text" [(ngModel)]="businessDetails.deliveryCharges">
            </div>
          </div>
          <button class="ok-btn" (click)="updateMinOrderAmt()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="main_section not-access-text" *ngIf="!viewAccess">You don't have view access</div>
<section class="loader_section" *ngIf="pageLoader">
  <div class="api_loader">
    <!-- <div class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div> -->
    <app-anime-loader></app-anime-loader>
  </div>
</section>
